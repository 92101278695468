import LogoSideBar from "./logo.png";
import DarkBackground from "./background-dark.png";
// import DarkBackground from "./background-dark.gif";
import LightBackground from "./background-light.png";
import MadetapeWeclome from "./Madtape.svg";

export default {
  LogoSideBar,
  DarkBackground,
  LightBackground,
  MadetapeWeclome,
};
