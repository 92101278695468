import React, { useState, useEffect } from "react";
import {
    Box,
    CircularProgress,
    useMediaQuery,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useGetMoviesQuery } from "../../services/TMDB";
import { MovieList, Pagination, FeaturedMovie } from "..";

const Movies = ({moviesNumber: moviesNumber=0}) => {
    const isAuthenticated = () => {
        return localStorage.getItem("request_token")!== null;
      };
    
    const [page, setPage] = useState(1);
    const { genreIdOrCategoryName, searchQuery } = useSelector(
        (state) => state.currentGenreOrCategory
    );
    const { data, error, isFetching } = useGetMoviesQuery({
        genreIdOrCategoryName,
        page,
        searchQuery,
    });

    const lg = useMediaQuery((theme) => theme.breakpoints.only("lg"));

    const numberOfMovies =  moviesNumber !==0 ? moviesNumber : lg ? 17 : 19;

    if (isFetching) {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress size="4rem" />
            </Box>
        );
    }

    if (!data.results.length) {
        return (
            <Box display="flex" alignItems="center" mt="20px">
                <Typography variant="h4">
                    No Movies matching that name.
                    <br />
                    Please search for something else.
                </Typography>
            </Box>
        );
    }

    if (error) return "An error has occurred.";

    // console.log(data);
    return (
        <div>
            <FeaturedMovie movie={data.results[0]} />
            <MovieList
                movies={data}
                numberOfMovies={numberOfMovies}
                excludeFirst
            />
            { isAuthenticated() &&  (<Pagination
                currentPage={page}
                setPage={setPage}
                totalPages={data.total_pages}
            />)}
        </div>
    );
};

export default Movies;
