import React, { useRef } from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom"; // Removed the comma since it's the last import
import useStyles from "./styles";

import Actors from "../../components/Actors/Actors";
import NavBar from "../../components/NavBar/NavBar";
import Movies from "../../components/Movies/Movies";
import MovieInformation from "../../components/MovieInformation/MovieInformation";
import Profile from "../../components/Profile/Profile";
import useAlan from "../../components/Alan";
import NavBarv2 from "../../components/NavBar/NavBarv2";

const HomePageV2 = () => {
    const classes = useStyles();
    const alanBtnContainer = useRef();

    useAlan();

    return (
        <div className={classes.root}>
            {/* <CssBaseline /> */}
            <NavBarv2 />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Routes>
                    <Route path="movie/:id" element={<MovieInformation />} />
                    <Route path="actors/:id" element={<Actors />} />
                    <Route index element={<Movies />} /> 
                    <Route path="approved" element={<Movies />} />
                    <Route path="profile/:id" element={<Profile />} />
                </Routes>
            </main>
            <div ref={alanBtnContainer} />
        </div>
    );
};

export default HomePageV2;
