import { makeStyles } from "@mui/styles";
import images from '../../assets/images';

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column", // Ensures the layout follows a vertical direction
        // minHeight: "100vh", // Makes the container at least the height of the viewport
        backgroundImage: `url(${theme.palette.mode === "light" ? images.LightBackground : images.DarkBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        marginTop: "60px",
        height: "100%",
    },
    content: {
        flexGrow: '1',
        padding: "5em",
        width:'100%',
    },
    
}));
