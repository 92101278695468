import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

export default makeStyles((theme,showSideBar) => ({
    appBar: {
        backgroundColor: theme.palette.mode === "light" ?  "#9C0000"  : "transparent"
    },
    toolbar: {
        // height: "80px",
        display: "flex",
        justifyContent: "space-between",
        marginLeft: showSideBar === true ? "240px" : "0px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            flexWrap: "wrap",
        },
        backgroundColor: theme.palette.mode === "light" ?  "#9C0000" : "transparent" 
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },

    drawer: {
        [theme.breakpoints.up("sm")]: {
            width:drawerWidth,
            flexShrink:0,
        },
    },
    drawerPaper : {
            width: drawerWidth,
    },
    linkButton:{
        '&:hover':{
            color:'while !important',
            textDecoration : 'none',
        }
    },
    imageLink: {
        display: "flex",
    },
    Logo: {
        width: "100%",
        height: "60px",
    },
}));
