import React, { useEffect, useState } from "react";
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    ListItemIcon,
    Box,
    CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/styles";
import useStyles from "./styles";
import { useGetGenresQuery } from "../../services/TMDB";
import genreIcons from "../../assets/genres";
import { useDispatch, useSelector } from "react-redux";
import { selectGenreOrCategory } from "../../features/currentGenreOrCategory";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import LogoSideBar from '../../assets/images/'
import Search from "../Search/Search";

const categories = [
    { label: "Popular", value: "popular" },
    { label: "Top Rated", value: "top_rated" },
    { label: "Upcoming", value: "upcoming" },
];

const darkLogo = LogoSideBar.LogoSideBar;
const lightLogo = LogoSideBar.LogoSideBar;

const Sidebar = ({ setMobileOpen }) => {
    const { genreIdOrCategoryName } = useSelector(
        (state) => state.currentGenreOrCategory
    );
    const theme = useTheme();
    const classes = useStyles();
    const { data, isFetching } = useGetGenresQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        setMobileOpen(false);
    }, [genreIdOrCategoryName]);
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };

    return (
        <>
            <div className={classes.sideBar}>
                {/* <Divider /> */}
                <List>
                    <ListSubheader style={{ backgroundColor: theme.palette.mode === "light" && "light" }} >Categories</ListSubheader>
                    {categories.map(({ label, value }) => (
                        <Link key={value} className={classes.links} to="/">
                            <ListItem
                                onClick={() =>
                                    dispatch(selectGenreOrCategory(value))
                                }
                            >
                                <ListItemIcon>
                                    <img
                                        src={genreIcons[label.toLowerCase()]}
                                        className={classes.genreImage}
                                        height={30}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={label} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
                <List>
                    <ListSubheader style={{ backgroundColor: theme.palette.mode === "light" && "light"}} className={classes.categoryTitle}>Genres</ListSubheader>
                    {isFetching ? (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        data.genres.map(({ name, id }) => (
                            <Link key={name} className={classes.links} to="/">
                                <ListItem
                                    onClick={() =>
                                        dispatch(selectGenreOrCategory(id))
                                    }
                                >
                                    <ListItemIcon>
                                        <img
                                            src={genreIcons[name.toLowerCase()]}
                                            className={classes.genreImage}
                                            height={30}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={name} />
                                </ListItem>
                            </Link>
                        ))
                    )}
                </List>
            </div>
        </>
    );
};

export default Sidebar;
