import { makeStyles } from "@mui/styles";
import images from '../../assets/images';

export default makeStyles((theme) => ({
    sideBar: {
        // backgroundColor: theme.palette.mode === "light" &&  "#9C0000",
        paddingTop: "10px",
        backgroundImage: `url(${theme.palette.mode === "light" ? images.LightBackground : images.DarkBackground})`,
    },
    categoryTitle: {
        fontWeight: "bold",
    },
    imageLink: {
        display: "flex",
        justifyContent: "center",
    },
    image: {
        width: "60%",
        display: "flex",
        justifyContent: "center",
        padding: "10% 0",
        marginRight: "5%",
    },
    links: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
            color: "red",
        },
    },
    genreImage: {
          filter : theme.palette.mode === 'dark' && 'invert(1)',
    },
}));
