import React, { useEffect, useContext } from "react";
import alanBtn from "@alan-ai/alan-sdk-web";
import { ColorModeContext } from "../utils/ToggleColorMode";
import { fetchToken } from "../utils";
import {
    searchMovie,
    selectGenreOrCategory,
} from "../features/currentGenreOrCategory";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Change this line from useHistory to useNavigate

const useAlan = () => {
    const { setMode } = useContext(ColorModeContext);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Change this line to use useNavigate hook

    useEffect(() => {
        alanBtn({
            key: "aa44334b0d8df7afa8d2dde33247a6562e956eca572e1d8b807a3e2338fdd0dc/stage", // Replace with your actual Alan AI key
            onCommand: ({ command, mode, genres, genreOrCategory, query }) => {
                if (command === "chooseGenre") {
                    const foundGenre = genres.find(
                        (g) => g.name.toLowerCase() === genreOrCategory.toLowerCase()
                    );
                    if (foundGenre) {
                        navigate("/"); // Change from history.push to navigate
                        dispatch(selectGenreOrCategory(foundGenre.id));
                    } else {
                        const category = genreOrCategory.startsWith("top") ? "top_rated" : genreOrCategory;
                        navigate("/"); // Change from history.push to navigate
                        dispatch(selectGenreOrCategory(category));
                    }
                } else if (command === "changeMode") {
                    setMode(mode === "light" ? "light" : "dark");
                } else if (command === "login") {
                    fetchToken();
                } else if (command === "logout") {
                    localStorage.clear();
                    navigate("/"); // Change from history.push to navigate
                } else if (command === "search") {
                    dispatch(searchMovie(query));
                }
            },
        });
    }, [dispatch, navigate, setMode]); // Add navigate and setMode to the dependency array
};

export default useAlan;