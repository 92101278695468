import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import ToggleColorModeProvider from "./utils/ToggleColorMode";
import "./index.css";
import { AppRoutes } from "./pages";
import '@fontsource/readex-pro/400.css'; // Regular weight
import '@fontsource/readex-pro/700.css'; // Bold weight

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <ToggleColorModeProvider>
        <BrowserRouter>
            <AppRoutes />
        </BrowserRouter>
    </ToggleColorModeProvider>
</Provider>,
);
