import axios from "axios";

// export const moviesApi = axios.create({
//     baseURL: "https://api.themoviedb.org/3",
//     params: {
//         api_key: process.env.REACT_APP_TMDB_KEY,
//     },
// });
export const moviesApi = axios.create({
    baseURL: "https://api.themoviedb.org/3",
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TMDB_TOKEN}`,
        'Content-Type': 'application/json;charset=utf-8'
    },
});

export const fetchToken = async (isGuest=true) => {
    try {
        if (isGuest) {
            localStorage.setItem("request_token", createSessionId());  
            localStorage.setItem("is_guest", true);  
            window.location.href = `/`;              
            // console.log("Guest token created");
        } else {
            const { data } = await moviesApi.get(`/authentication/token/new`);
            const token = data.request_token;
            if (data.success) {  
                localStorage.setItem("request_token", token);
                window.location.href = `https://www.themoviedb.org/authenticate/${token}?redirect_to=${window.location.origin}/approved`;
            }
        }
        
    } catch (error) {
        // console.log("Your token could not be created");
    }
};

export const createSessionId = async (isGuest=true) => {
    const token = localStorage.getItem("request_token");

    if (token) {
        try {
            if (isGuest) {
                const {
                    data: { guest_session_id },
                } = await moviesApi.get("authentication/guest_session/new");
                localStorage.setItem("session_id", guest_session_id);
                // console.log(guest_session_id);
                return guest_session_id;
            } else {
                const {
                    data: { session_id },
                } = await moviesApi.post("authentication/session/new", {
                    request_token: token,
                });
                localStorage.setItem("session_id", session_id);
                return session_id;
            }
            
            
        } catch (error) {
            console.log(error);
        }
    }
};
