import { makeStyles } from "@mui/styles";
import images from '../../assets/images';

export default makeStyles((theme) => ({
    loginModel: {
        // display: "flex",
        // flexDirection: "column", 
        minHeight: "100vh", 
        paddingTop: "60px", 
        // backgroundImage: `url(${theme.palette.mode === "light" ? images.LightBackground : images.DarkBackground})`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // width: "40%",
    },
}));
