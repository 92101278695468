import React from "react";
import { Typography, Button } from "@mui/material";
import useStyles from "./styles";

const Pagination = ({ currentPage, totalPages, setPage }) => {
    const classes = useStyles();

    const handlePrev = () => {
        if (currentPage != 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage != totalPages) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    if (totalPages === 0) return null;

    return (
        <div className={classes.container}>
            <Button
                onClick={handlePrev}
                className={classes.button}
                variant="contained"
                color="primary"
                type="button"
            >
                Prev
            </Button>
            <Typography variant="h6" className={classes.pageNumber}>
                {currentPage}
            </Typography>
            <Typography variant="h6" onClick={()=>setPage(currentPage+1)} className={classes.pageNumber}>
                {currentPage+1}
            </Typography>
            <Typography variant="h6" onClick={()=>setPage(currentPage+2)} className={classes.pageNumber}>
                {currentPage+2}
            </Typography>
            <Button
                onClick={handleNext}
                className={classes.button}
                variant="contained"
                type="button"
            >
                Next
            </Button>
        </div>
    );
};

export default Pagination;
