import { makeStyles } from "@mui/styles";
import images from '../../assets/images';

const drawerWidth = 240;

export default makeStyles((theme,showSideBar) => ({
    sideBarImage: {
        width: "60%",
        display: "flex",
        justifyContent: "center",
        padding: "10% 0",
        marginRight: "5%",
    },
    appBar: {
        // backgroundImage: `url(${theme.palette.mode === "light" && images.LightBackground })`,
        //backgroundColor: `url(${theme.palette.mode === "light" ? "red" : images.DarkBackground})`,
    },
    toolbar: {
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // marginLeft: showSideBar === true ? "240px" : "0px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            flexWrap: "wrap",
        },
        backgroundColor: theme.palette.mode === "light" ?  "#9C0000" : "black" 
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    loginButtonOffline: {
        color: theme.palette.mode == "light" ? "#9C0000" : "white",
        borderRadius: "8px",
        backgroundColor: theme.palette.mode == "dark" ? "#9C0000" : "white",
         "&:hover": {
            backgroundColor: theme.palette.mode == "light" ? "#9C0000" : "transparent",
            color: "white",
            border: "1px solid white",
        }
        },

    drawer: {
        [theme.breakpoints.up("sm")]: {
            width:drawerWidth,
            flexShrink:0,
        },
    },
    drawerPaper : {
            width: drawerWidth,
    },
    linkButton:{
        '&:hover':{
            color:'while !important',
            textDecoration : 'none',
        }
    },
    imageLink: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
    },
    Logo: {
        // width: "100%",
        height: "60px",
        marginRight: "auto",
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
            //display: "none",
            height: "40px",
        },

    },
    searchField: {
        // width: "100%",
        flexGrow: 1,
        display: "flex",
        // justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    }
}));
