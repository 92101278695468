import React, { useState } from "react";
import { Box, Typography, Paper } from '@mui/material';
import useStyles from './styles'; // Adjust the import path to where your styles.js is
import images from "../../assets/images";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import madtapeVideo from "../../assets/videos/madtape.mp4";
import { Button } from "@mui/material";

import { Modal } from "@mui/material";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

function MadtapeBanner() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box className={classes.root}>
      
      <Typography variant="h2" className={classes.welcomeText}>
         Welcome  <span color="white"> To </span> <span className={classes.madtapeText}> Madtape </span> 
      </Typography>
      
      <Typography variant="subtitle1" className={classes.subTitle}>
        It is Free Now and Forever
      </Typography>
        <img
          src={images.MadetapeWeclome}
          alt="Madtape Logo"
          className={classes.madtapeLogo}
        />
        {/* <video
          autoPlay
          className={classes.madtapeVideo}
          title="Intro video"
          src={madtapeVideo}
          onClick={(event) =>(event.target.stop())}
          allow="autoplay"
        /> */}
        {/* <VideoPlayer classes={classes} madtapeVideo={madtapeVideo} /> */}
         <Button className={classes.videoButton} onClick={() => setOpen(true)} href="#" >
      <PlayCircleFilledIcon sx={{ fontSize: 80, color: "red" }} />
      </Button>
         {/* <main className={classes.content}>/
                <div className={classes.toolbar} />
            </main> */}
        <Modal
        closeAfterTransition
        className={classes.modal}
        sx={{ width: "100%", height: "100%" }}
        open={open}
        onClose={() => setOpen(false)}
      > 
        <iframe
          autoPlay
          className={classes.video}
          title="Intro video"
          
          src={madtapeVideo}
          allow="autoplay"
        />
      </Modal>
    </Box>
  );
}

export default MadtapeBanner;
