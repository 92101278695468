import React, { useRef } from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom"; // Removed the comma since it's the last import
import useStyles from "./styles";

import NavBar from "../../components/NavBar/NavBar";
import LoginModel from "../../components/LoginModel/LoginModel";
import Footer from "../../components/Footer/Footer";
import NavBarv2 from "../../components/NavBar/NavBarv2";

const LoginPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <NavBarv2 />
            <LoginModel /> 
            <Footer />
        </div>
    );
};

export default LoginPage;
