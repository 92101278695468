import { makeStyles } from "@mui/styles";
import images from '../../assets/images';

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column", 
        minHeight: "100vh", 
        backgroundImage: `url(${theme.palette.mode === "light" ? images.LightBackground : images.DarkBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // paddingTop: "7%",
    },
    // toolbar: {
    //     height: "70px",
    // },
    // content: {
    //     flexGrow: 1, // Ensures that the content expands to fill available space, pushing the footer down
    //     padding: "2em",
    //     width: '100%',
    // },
}));
