import React, { useRef, useState } from "react";
import { Button, CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom"; // Removed the comma since it's the last import
import useStyles from "./styles";
import NavBar from "../../components/NavBar/NavBar";
import Movies from "../../components/Movies/Movies";
import Footer from "../../components/Footer/Footer";
import NavBarv2 from "../../components/NavBar/NavBarv2";
import { HomePageBanner } from "../../components";
import images from "../../assets/images/";


const HomePage = () => {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <NavBarv2 />

      {/* <video src={madtapeVideo} height="100hv" allow="autoplay" title="intro video" frameBorder="0">

            </video> */}
     
      <HomePageBanner  />
      {/* <div onClick={() => setOpen(true)}>
      
      </div> */}
      

      <Movies moviesNumber={7} />

     
      <Footer />
    </div>
  );
};

export default HomePage;
