import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import { setUser, userSelector } from "../../features/auth";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./stylesV2";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { ColorModeContext } from "../../utils/ToggleColorMode";
import { fetchToken, createSessionId, moviesApi } from "../../utils";
import images from "../../assets/images/";
import { Search, Sidebar } from "..";
import { AccountCircle, Brightness4, Brightness7 } from "@mui/icons-material";
import LoginButton from "../LoginButton/LoginButton";

const darkLogo = images.LogoSideBar;
const lightLogo = images.LogoSideBar;

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundImage: `url(${
    theme.palette.mode === "light"
      ? images.LightBackground
      : images.DarkBackground
  })`,
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NavBarv2() {
  const [open, setOpen] = useState(false);
  const { isAuthenticated, user } = useSelector(userSelector);
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const colorMode = useContext(ColorModeContext);

  const token = localStorage.getItem("request_token");
  const sessionIdFromLocalStorage = localStorage.getItem("session_id");
  const is_guest = localStorage.getItem("is_guest");

  useEffect(() => {
    const logInUser = async () => {
      if (token) {
        if (!sessionIdFromLocalStorage) {
          if (!is_guest ){
            const { data: userData } = await moviesApi.get(
              `/account?session_id=${sessionIdFromLocalStorage}`
            );
            dispatch(setUser(userData));
          } else {
            const userData = {
              id: "Madtaper",
              username: "Madtaper",
              email: "",
              name: "Madtaper",
              last_name: "",
            }
            dispatch(setUser(userData));
          }
        }
      }
    };
    logInUser();
  }, [token]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
                ...(open && { display: "none" }),
              }}
            >
              {/* <MenuIcon /> */}
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          )}

          <Link to="/" className={classes.imageLink}>
            <img
              className={classes.Logo}
              src={theme.palette.mode === "light" ? lightLogo : darkLogo}
              alt="Madtape Logo"
              onClick={() => isAuthenticated? navigate("/") : navigate("/en")}
            />
          </Link>
          {/* <Typography variant="h6"  noWrap component="div">
                        Welcome to Madtape!
                    </Typography> */}
          {isAuthenticated && (
            <div className={classes.searchField}>
              <Search isAuthenticated={isAuthenticated} />
            </div>
          )}
          {!isAuthenticated && (
            <IconButton
              color="inherit"
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7 />
              ) : (
                <Brightness4 />
              )}
            </IconButton>
          )}
          {!isAuthenticated ? (
            <Button
              color="inherit"
              onClick={() => {
                // fetchToken();
                navigate("/login", { replace: true });
              }}
              className={classes.loginButtonOffline}
              sx={{
                color: theme.palette.mode == "light" ? "#9C0000" : "white",
                borderRadius: "8px",
                marginLeft: "10px",
                backgroundColor: theme.palette.mode == "dark" ? "#9C0000" : "white",
                "&:hover": {
                    backgroundColor: theme.palette.mode == "light" ? "#9C0000" : "transparent",
                    color: "white",
                    border: "1px solid white",
                }
              }}
            >
              Login &nbsp; <AccountCircle />
            </Button>
          ) : (
            <LoginButton />
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {/* <img
            className={classes.sideBarImage}
            src={theme.palette.mode === "light" ? lightLogo : darkLogo}
            alt="Madtape Logo"
          /> */}
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Sidebar setMobileOpen={setMobileOpen} />

          <Divider />
        </Drawer>
      )}
    </Box>
  );
}
