import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { LandingPage, HomePage, LoginPage } from './';

const isAuthenticated = () => {
  return localStorage.getItem("request_token") !== null;
};

const ProtectedRoute = () => {
  const location = useLocation();
  return isAuthenticated() ? <Outlet /> : <Navigate to="/en" state={{ from: location.pathname }} replace />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route index element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
      </Route>
      <Route path="/en" element={<LandingPage />} >
        <Route path="*" element={<LandingPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} >
        <Route path="*" element={<LoginPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
