import { makeStyles } from "@mui/styles";
import images from "../../assets/images/";

const madtapeRed = "#DC143C"; // Adjust to match the exact red you want for "Madtape"
const welcomeGray = "#808080"; // A generic gray; adjust as needed

export default makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "30px",
    backgroundImage: `url(${theme.palette.mode === "light" ? images.LightBackground : images.DarkBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    fontFamily: "Readex Pro, sans-serif", // Make sure the font is loaded
  },
  welcomeText: {
    // color: welcomeGray, // Gray color for "Welcome to"
    fontSize: "4rem", 
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", 
    textAlign: "center",
    [(theme) => theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
    [(theme) => theme.breakpoints.down("sm")]: {
      fontSize: "2rem", // Adjust size as needed
      justifyContent: "row",
      // marginBottom: "10px", // Adjust spacing as needed
      flexDirection: "column",
    },
  },
  madtapeText: {
    color: madtapeRed, // Adjust to match the exact red you want for "Madtape
  },
  subTitle: {
    color: theme.palette.mode == "light" ? madtapeRed : "#F5F5F5", // Off-white color; adjust as needed
    fontSize: "2rem", // Adjust size as needed
    textAlign: "center",
    fontWeight: "normal",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)", // Shadow cast downwards
  },
  madtapeLogo: {
    maxWidth: "30%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  },
  madtapeVideo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
    width: "50%",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "90%",
    },
  },
  videoButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "90%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  video: {
    width: "50%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "90%",
    }
  },
    fullScreenButton: {
      position: 'absolute',
      bottom: 20,
      right: 20,
      backgroundColor: 'white', // Or any other color
      color: 'black', // Or any other color
      '&:hover': {
        backgroundColor: '#f0f0f0', // Lighter shade for hover state
      },
      opacity: 0.7,
      transition: 'opacity 0.3s',
      '&:hover': {
        opacity: 1,
      },
  },
}));
