import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import WalletIcon from '@mui/icons-material/Wallet';
import { AccountCircle, Brightness4, Brightness7, ExitToAppRounded} from "@mui/icons-material";
import {
    Divider,MenuItem,Avatar,MenuList,Popper,Paper,Grow,IconButton, ButtonBase,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { userSelector } from '../../features/auth';
import { fetchToken, createSessionId, moviesApi } from "../../utils";
import useStyles from "./styles";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { ColorModeContext } from '../../utils/ToggleColorMode';
import images from '../../assets/images';

export default function LoginButton({ options: options, ...rest }) {
    const { isAuthenticated, user } = useSelector(userSelector);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState();
    const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const is_guest = localStorage.getItem("is_guest");

    const logout = () => {
        localStorage.clear();
        window.location.href = "/en";
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        switch (index) {
            case -2 :
                colorMode.toggleColorMode();
                setOpen(true);
                break;
            case -1:
                logout();
                navigate("/login", { replace: true });
                setSelectedIndex(-1);
                break;
            case 0:
                navigate(`/profile/${user.id}`, { replace: true });
                break;
            case 1:
                //navigate("/wallet")
                break;
            case 2:
                navigate("/register");
                break;
            default:
                break;
        }
        
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {
                !isAuthenticated ? (
                    <React.Fragment>
                    <Button color="inherit" className={classes.loginButton} onClick={()=> (navigate("/login"))}>
                        Login &nbsp; <AccountCircle />
                    </Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ButtonGroup
                            // variant="contained"
                            ref={anchorRef}
                        // aria-label="Button group with a nested menu"
                        >
                            <ButtonBase
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="Logging button"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            sx={{fontSize: 20}}
                            >
                            {user?.name} &nbsp; <Avatar
                                    sx={{ width: 40, height: 40, fontSize: 24 }}
                                    alt="Profile"
                                    src={ is_guest ? images.LogoSideBar :`https://www.themoviedb.org/t/p/w64_and_h64_face${user?.avatar?.tmdb?.avatar_path}`}
                                />
                            </ButtonBase>
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            className={classes.popper}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList className={classes.menuList} id="split-button-menu" autoFocusItem>
                                                <MenuItem
                                                    key={"profile"}
                                                    selected={0 === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, 0)}
                                                >
                                                   <Avatar
                                                    style={{ width: 30, height: 30 }}
                                                    alt="Profile"
                                                    src={`https://www.themoviedb.org/t/p/w64_and_h64_face${user?.avatar?.tmdb?.avatar_path}`}
                                                /> &nbsp;&nbsp; {user?.id}
                                                </MenuItem>
                                                
                                                <Divider />
                                                <MenuItem
                                                    key={"wallet"}
                                                    selected={1 === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, 1)}
                                                >
                                                   <WalletIcon /> &nbsp;&nbsp; Wallet
                                                </MenuItem>
                                                <Divider />
                                                
                                                <MenuItem
                                                    key={"colormode"}
                                                    selected={-2 === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, -2)}
                                                >
                                                    {theme.palette.mode === "dark" ? (
                                                        <Brightness7 />
                                                    ) : (
                                                        <Brightness4 />
                                                    )}
                                                   &nbsp;&nbsp; {theme.palette.mode === "light" ? (
                                                        "Dark mode"
                                                    ) : (
                                                        "Light mode"
                                                    )}
                                                </MenuItem>
                                                <MenuItem
                                                    key={"logout"}
                                                    selected={-1 === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, -1)}
                                                >
                                                   <ExitToAppRounded /> &nbsp;&nbsp; Logout
                                                </MenuItem>
                                                
                                                {/* {options.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index+2 === selectedIndex}
                                                        onClick={(event) => handleMenuItemClick(event, index+2)}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))} */}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </React.Fragment>
                )
            }
        </>
    );
}
