import { makeStyles } from "@mui/styles";

const drawerWidth = 200;

export default makeStyles((theme) => ({
  loginButton: {
    // backgroundColor: "red"
  },
  popper: {
        width: drawerWidth,
        overflow: "auto",
        paddingRight: "24px",
        paddingTop: "5px",
        // animation: "slide"
    },
}));
